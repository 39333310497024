<template>
  <div>
    <CCardBody class="c-card-body">
      <router-link :to="`/orders/create`">
        <CButton color="success">Создать заказ</CButton>
      </router-link>
      <br />
      <br />
      <CDataTable
          :items="orders"
          :fields="fields"
          hover
          border
          sorter
          :sorterValue="{ column: 'created', asc: false }"
          :tableFilter="{ label: 'Поиск', placeholder: 'введите код' }"
      >
        <template #code="{ item }">
          <td class="td">
            <router-link :to="`/orders/${item.id}/plan`">
              {{ item.code }}
            </router-link>
          </td>
        </template>
        <template #user="{ item }">
          <td class="td">
              {{ item.user.first_name }}

          </td>
        </template>
        <template #created="{ item }">
          <td class="td">
            {{ getDate(item) }}
          </td>
        </template>
      </CDataTable>
    </CCardBody>
  </div>
</template>

<style>
.test {
  background-color: red;
}

.c-card-body {
  background-color: #fefefe;
}

table {
  font-family: "Lucida Sans Unicode", "Lucida Grande", Sans-Serif;
  font-size: 14px;
  background: white;
  max-width: 100%;
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

th {
  font-weight: normal;
  color: #039;
  border-bottom: 2px dashed #6678b1;
  padding: 10px 8px;
}

.td {
  color: #669;
  padding: 9px 8px;
  transition: 0.3s linear;
}

tr:hover .td {
  background: #e8edff;
}

.td {
  border-bottom: 0.5px dashed #ccc;
  color: #669;
  padding: 9px 8px;
  transition: 0.3s linear;
}

th:nth-child(-n + 3),
.td:nth-child(-n + 3) {
  text-align: left;
}

.item-right {
  text-align: right;
}
</style>

<script>
import { mapState, mapActions } from "vuex";

const fields = [
  {
    key: "code",
    label: "Код заказа",
  },
  {
    key: "created",
    label: "Дата создания",
  },
  {
    key: "status",
    label: "Состояние",
  },
  {
    key: "client",
    label: "Покупатель",
  },
  {
    key: "user",
    label: "Менеджер",
  },
];

export default {
  name: "Orders",
  data() {
    return {
      fields,
    };
  },
  computed: {
    ...mapState({
      orders: (state) => state.orders.all,
    }),
  },
  created() {
    this["orders/getAll"]();
  },
  methods: {
    ...mapActions(["orders/getAll"]),
    getDate(item) {
      return new Intl.DateTimeFormat("ru-RU").format(new Date(item.created));
    },
  },
};
</script>
